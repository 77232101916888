import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.changePage.type: {
			const newState = {
				...state,
				leadsLoading: true,
				leadsQuery: {...state.leadsQuery, _page: payload},
			};

			return [newState, effects.updateLeads()];
		}

		case actions.recheckQuery.type: {
			return [state, effects.recheckQuery()];
		}

		case actions.removeLead.type: {
			return [state, effects.removeLead(payload)];
		}

		case actions.showSalesmanagerLeads.type: {
			const newState = {
				...state,
				leadsLoading: true,
				leadsQuery: {
					...initState.leadsQuery,
					salesmanagerLeads: true,
				},
			};

			return [newState, effects.updateLeads()];
		}

		case actions.showPersonalLeads.type: {
			const newState = {
				...state,
				leadsLoading: true,
				leadsQuery: {
					...initState.leadsQuery,
					salesmanagerLeads: false,
				},
			};

			return [newState, effects.updateLeads()];
		}

		case actions.updateLeadsQuery.type: {
			const keys = [
				'marketingSourceId',
				'marketingCategoryId',
				'marketingCustomerTypeId',
				'clientName',
				'phone',
			];
			const newState = {
				...state,
				leadsLoading: true,
				leadsQuery: {
					...state.leadsQuery,
					...keys.reduce((acc, key) => {
						if (Object.hasOwn(payload, key)) {
							acc[key] = payload[key];
						}
						return acc;
					}, {}),
				},
			};

			return [newState, effects.updateLeads()];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._updateLeadsQuery.type: {
			const newState = {
				...state,
				leadsLoading: true,
				leadsQuery: {...state.leadsQuery, ...payload},
			};

			return [newState, null];
		}

		case actions._setLeads.type: {
			const {data, pagination} = payload;
			const newState = {
				...state,
				leads: data,
				leadsPagination: pagination,
				leadsLoading: false,
				processing: false,
			};
			return [newState, null];
		}

		case actions._removedLead.type: {
			return [{...state, leadToDelete: null, processing: false}, null];
		}

		case actions._setProcessing.type: {
			return [{...state, processing: payload}, null];
		}

		case actions._opFailed.type: {
			return [{state, leadsLoading: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
