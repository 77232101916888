import {concat, reject, contains, pipe, assoc, append} from 'ramda';
import {getFormValues} from 'redux-form';
import {scopedHandler, pipeSubHandlers} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import * as selectors from './selectors';
import * as commonSelectors from 'modules/common/selectors';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {
	formatCalendarResourceAddFormOutput,
	formatCalendarEncounterAddFormOutput,
	formatItemReservationCancellationOutput,
	formatCalendarResourceEditFormOutput,
	formatCalendarResourceDateFormOutput,
	sortTeams,
	parseAddBuildingFormInitValues,
	formatAddBuildingOutput,
	formatClientFormOutput,
	formatCalendarEventCreateFormOutput,
	formatCalendarEventEditFormOutput,
} from './utils';
import {normalizeCalendarResources, normalizeItem} from 'utils/calendarResources';
import createReminderHandler from 'fragments/callReminder/handler';
import {setHour} from 'utils/time';

const pipeHandlers = pipeSubHandlers(ns);

const reminderHandler = createReminderHandler({
	actions,
	effects,
	weekSampleSelector: selectors.weekSample,
	buildingSelector: selectors.callReminderBuilding,
});

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.updateCalendarQuery.type: {
			const newState = {
				...state,
				callReminders: initState.callReminders,
				hourReminders: initState.hourReminders,
				calResLoading: true,
				calendarQuery: {
					...state.calendarQuery,
					...payload,
				},
			};

			return [
				newState,
				effects.updateCalendarResources(
					state.calendarQuery.teamId !== newState.calendarQuery.teamId,
				),
			];
		}

		case actions.updateCalendarResources.type: {
			return [{...state, calResLoading: true}, effects.updateCalendarResources()];
		}

		case actions.createCalendarResources.type: {
			const items = formatCalendarResourceAddFormOutput({
				form: payload,
				slotSelection: state.slotSelection,
				teamId: state.calendarQuery.teamId,
			});

			return [{...state, processing: true}, effects.createCalendarResources(items)];
		}

		case actions.removeCalendarResources.type: {
			return [state, effects.removeCalendarResources(payload)];
		}

		case actions.updateCalendarResource.type: {
			return [{...state, processing: true}, effects.updateCalendarResource(payload)];
		}

		case actions.toggleItemsViewer.type: {
			return [{...state, itemsViewerOpen: payload, itemsCreatorOpen: false}, null];
		}

		case actions.toggleItemsCreator.type: {
			return [
				{...state, itemsCreatorOpen: payload, itemsViewerOpen: false},
				effects.toggleItemsCreator(),
			];
		}

		case actions.setSlotSelection.type: {
			return [{...state, slotSelection: payload}, null];
		}

		case actions.clearSlotSelection.type: {
			return [
				{
					...state,
					slotSelection: initState.slotSelection,
					itemsViewerOpen: false,
					itemsCreatorOpen: false,
				},
				null,
			];
		}

		case actions.searchBuildings.type: {
			return [state, effects.searchBuildings(payload)];
		}

		case actions.searchUsers.type: {
			return [state, effects.searchUsers(payload)];
		}

		case actions.createEncounter.type: {
			const {calendarResource, formFill} = formatCalendarEncounterAddFormOutput({
				form: payload,
				slotSelection: state.slotSelection,
				teamId: state.calendarQuery.teamId,
			});

			return [
				{...state, processing: true},
				effects.createEncounter({calendarResource, formFill}),
			];
		}

		case actions.refreshSelectedBuildingClients.type: {
			return [state, effects.refreshSelectedBuildingClients(payload)];
		}

		case actions.setSelectedItem.type: {
			return [
				{
					...state,
					selectedItem: payload,
					areasEditorOpen: false,
					dateEditorOpen: false,
				},
				null,
			];
		}

		// copy-paste
		case actions._setSelectedItem.type: {
			return [
				{
					...state,
					selectedItem: payload,
					areasEditorOpen: false,
					dateEditorOpen: false,
				},
				null,
			];
		}

		case actions.openEncounterPreview.type: {
			return [{...state, processing: true}, effects.getPreviewableEncounter(payload)];
		}

		case actions.closeEncounterPreview.type: {
			return [{...state, previewableEncounter: null}, null];
		}

		case actions.setItemReservationCancellation.type: {
			const item = formatItemReservationCancellationOutput(state.selectedItem, payload);

			return [{...state, processing: true}, effects.updateCalendarResource(item)];
		}

		case actions.setAreasEditorOpen.type: {
			return [{...state, areasEditorOpen: payload}, null];
		}

		case actions.setDateEditorOpen.type: {
			return [{...state, dateEditorOpen: payload}, null];
		}

		case actions.saveCalendarResourceEditForm.type: {
			const teams = state.teams;
			const resource = formatCalendarResourceEditFormOutput({
				form: payload,
				selectedItem: state.selectedItem,
				teams,
			});

			return [{...state, processing: true}, effects.updateCalendarResource(resource)];
		}

		case actions.fetchBuildingCalendarResources.type: {
			return [
				{
					...state,
					buildingCalendarResourcesLoading: true,
				},
				effects.fetchBuildingCalendarResources(payload),
			];
		}

		case actions.clearBuildingCalendarResources.type: {
			return [
				{
					...state,
					buildingCalendarResources: [],
					buildingCalendarResourcesLoading: false,
				},
				null,
			];
		}

		case actions.openCalendarBuildingModal.type: {
			return [
				{
					...state,
					calendarBuildingModalOpen: true,
					building: payload,
					buildingCalendarResourcesLoading: true,
				},
				effects.fetchBuildingCalendarResources(payload.id),
			];
		}

		case actions.closeCalendarBuildingModal.type: {
			return [
				{
					...state,
					calendarBuildingModalOpen: false,
					building: null,
					buildingCalendarResources: [],
					buildingCalendarResourcesLoading: false,
				},
				null,
			];
		}

		case actions.openBonusItemReservationModal.type: {
			return [
				{...state, bonusItemReservationModalOpen: true},
				effects.setBonusItemBeingReserved(payload),
			];
		}

		case actions.closeBonusItemReservationModal.type: {
			return [
				{...state, bonusItemReservationModalOpen: false, bonusItemBeingReserved: null},
				null,
			];
		}

		case actions.reserveBonusItem.type: {
			const resource = formatCalendarResourceDateFormOutput({
				form: payload,
				item: state.bonusItemBeingReserved,
				userId: commonSelectors.user(fullState).id,
			});

			return [{...state, processing: true}, effects.reserveBonusItem(resource)];
		}

		case actions.openAddBuildingModal.type: {
			// parse initial address and zip from search string
			const addBuildingFormInitValues = parseAddBuildingFormInitValues(payload);

			return [
				{
					...state,
					addBuildingModalOpen: true,
					addBuildingFormInitValues,
				},
				null,
			];
		}

		case actions.closeAddBuildingModal.type: {
			return [
				{
					...state,
					addBuildingModalOpen: false,
					addBuildingFormInitValues: initState.addBuildingFormInitValues,
				},
				null,
			];
		}

		case actions.openMapModal.type: {
			return [
				{...state, mapModalOpen: true, buildingToAdd: payload},
				effects.initAddBuildingMap(),
			];
		}

		case actions.closeMapModal.type: {
			return [
				{...state, mapModalOpen: false, buildingToAdd: initState.buildingToAdd},
				null,
			];
		}

		case actions.addBuilding.type: {
			const building = formatAddBuildingOutput(state.buildingToAdd);
			return [{...state, processing: true}, effects.addBuilding(building)];
		}

		case actions.openRoutePlanner.type: {
			return [state, effects.openRoutePlanner()];
		}

		case actions.onReminderClick.type: {
			return [state, effects.onReminderClick(payload)];
		}

		case actions.reinitialize.type: {
			return [initState, effects.initialize(payload)];
		}

		case actions.toggleClientEditor.type: {
			const newState = {
				...state,
				clientEditorOpen: !state.clientEditorOpen,
			};

			return [newState, null];
		}

		case actions.saveClient.type: {
			const client = formatClientFormOutput(payload);
			const encounterAddForm = getFormValues('calendarEncounterAddForm')(fullState);
			const buildingId = encounterAddForm.building?.id;

			return [{...state, processing: true}, effects.createClient({client, buildingId})];
		}

		case actions.toggleLockModal.type: {
			return [{...state, lockModalOpen: !state.lockModalOpen}, null];
		}

		case actions._setMarketingLeadSources.type: {
			return [{...state, marketingLeadSources: payload}, null];
		}

		case actions._updateCalendarQuery.type: {
			return [{...state, calendarQuery: {...state.calendarQuery, ...payload}}, null];
		}

		case actions._setCalendarResources.type: {
			const [hourlessItems, hourItems] = normalizeCalendarResources(
				state.calendarQuery.weekSample,
				payload,
			);

			const newState = {
				...state,
				hourlessItems,
				hourItems,
				itemsSource: payload,
				calResLoading: false,
			};

			return [newState, null];
		}

		case actions._addCalendarResources.type: {
			const itemsSource = concat(state.itemsSource, payload);
			const [hourlessItems, hourItems] = normalizeCalendarResources(
				state.calendarQuery.weekSample,
				itemsSource,
			);

			const newState = {
				...state,
				hourlessItems,
				hourItems,
				itemsSource,
			};

			return [newState, null];
		}

		case actions._removeCalendarResources.type: {
			const removedIds = payload.map(r => r.id);
			const itemsSource = reject(i => contains(i.id, removedIds), state.itemsSource);
			const [hourlessItems, hourItems] = normalizeCalendarResources(
				state.calendarQuery.weekSample,
				itemsSource,
			);

			const newState = {
				...state,
				hourlessItems,
				hourItems,
				itemsSource,
			};

			return [newState, null];
		}

		case actions._updateCalendarResource.type: {
			let newItemsSource = state.itemsSource;

			if (state.selectedItem && state.selectedItem.teamId !== payload.teamId) {
				// item's team was changed, add or remove it from itemsSource depending on active team
				// item's team can only be changed if it's selected, so we can check the initial team from selectedItem
				const activeTeamId = state.calendarQuery.teamId;
				if (activeTeamId === 'requests') {
					// requests selected => shows items from all teams in active org
					if (!state.activeOrganizationTeams.find(t => t.id === payload.teamId)) {
						// moved item to another org, remove it
						newItemsSource = reject(i => i.id === payload.id, state.itemsSource);
					} else if (!state.itemsSource.find(i => i.id === payload.id)) {
						// moved item from another org to active org, add it
						newItemsSource = append(payload, state.itemsSource);
					} else {
						// moved item to another team in active org, update it
						newItemsSource = state.itemsSource.map(i => {
							if (i.id === payload.id) return payload;
							return i;
						});
					}
				} else if (payload.teamId === activeTeamId) {
					newItemsSource = append(payload, state.itemsSource);
				} else {
					newItemsSource = reject(i => i.id === payload.id, state.itemsSource);
				}
			} else {
				// update the item in itemsSource
				newItemsSource = state.itemsSource.map(i => {
					if (i.id === payload.id) return payload;
					return i;
				});
			}

			const [hourlessItems, hourItems] = normalizeCalendarResources(
				state.calendarQuery.weekSample,
				newItemsSource,
			);

			// if the updated item is currently selected, update the selected item
			const selectedItem =
				state.selectedItem && state.selectedItem.id === payload.id
					? pipe(normalizeItem, assoc('virtual', state.selectedItem.virtual))(payload)
					: state.selectedItem;

			// if the updated item is in buildingCalendarResources, update it there too
			const buildingCalendarResources = state.buildingCalendarResources.length
				? state.buildingCalendarResources.map(r => {
						if (r.id === payload.id) return payload;
						return r;
				  })
				: state.buildingCalendarResources;

			const newState = {
				...state,
				hourlessItems,
				hourItems,
				itemsSource: newItemsSource,
				selectedItem,
				buildingCalendarResources,
			};

			return [newState, null];
		}

		case actions._replaceItemById.type: {
			const updatedHourItems = state.hourItems.map(day => {
				const updatedDay = {};
				Object.entries(day).forEach(([hour, items]) => {
					if (items.find(i => i.id === payload.id)) {
						updatedDay[hour] = items.map(i => (i.id === payload.id ? payload : i));
					} else {
						updatedDay[hour] = items;
					}
				});
				return updatedDay;
			});
			return [{...state, hourItems: updatedHourItems}, null];
		}

		case actions._setActiveOrganizationTeams.type: {
			const activeOrganizationTeams = sortTeams(payload);
			return [{...state, activeOrganizationTeams}, null];
		}

		case actions._setAllTeams.type: {
			const teams = sortTeams(payload);
			return [{...state, teams}, null];
		}

		case actions._setPreviewableEncounter.type: {
			return [{...state, processing: false, previewableEncounter: payload}, null];
		}

		case actions._resetEditors.type: {
			return [{...state, areasEditorOpen: false, dateEditorOpen: false}, null];
		}

		case actions._setBuildingCalendarResources.type: {
			return [
				{
					...state,
					buildingCalendarResources: payload,
					buildingCalendarResourcesLoading: false,
				},
				null,
			];
		}

		case actions._setBonusItemBeingReserved.type: {
			return [{...state, bonusItemBeingReserved: payload}, null];
		}

		case actions._setBuildingToAddCoords.type: {
			return [{...state, buildingToAdd: {...state.buildingToAdd, coords: payload}}, null];
		}

		case actions._addBuilding.type: {
			return [
				{
					...state,
					processing: false,
					mapModalOpen: false,
					addBuildingModalOpen: false,
					buildingToAdd: initState.buildingToAdd,
					addBuildingFormInitValues: initState.addBuildingFormInitValues,
				},
				null,
			];
		}

		case actions._setBuilding.type: {
			return [{...state, ...payload}, null];
		}

		case actions._setLead.type: {
			return [{...state, lead: payload}, null];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._startOp.type: {
			return [{...state, processing: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false}, null];
		}

		case actions.setCallReminderBuilding.type: {
			return [{...state, callReminderBuilding: payload}, null];
		}

		case actions._setUserTeam.type: {
			return [{...state, userTeam: payload}, null];
		}

		case actions._setProducts.type: {
			return [{...state, products: payload}, null];
		}

		case actions._openToDate.type: {
			// getDay starts from sunday (sun = 0, mon = 1, ...)
			// iDay starts from monday (mon = 0, tue = 1, ...)
			const day = payload.getDay();
			const iDay = day === 0 ? 6 : day - 1;

			const newState = {
				...state,
				slotSelection: {
					type: 'hour',
					date: setHour(payload, 0, 0, 0, 0),
					iDay,
					hour: payload.getHours(),
				},
				itemsViewerOpen: true,
				itemsCreatorOpen: false,
			};

			return [newState, null];
		}

		case actions._clientSaved.type: {
			return [{...state, processing: false, clientEditorOpen: false}, null];
		}

		case actions._setLockData.type: {
			const {data, lockFrom} = payload;

			return [
				{
					...state,
					lockingResources: data,
					lockFrom: lockFrom ? new Date(lockFrom) : null,
				},
				null,
			];
		}

		case actions.saveCalendarEventEditForm.type: {
			const resource = formatCalendarEventEditFormOutput({
				form: payload,
				selectedItem: state.selectedItem,
			});

			return [{...state, processing: true}, effects.updateCalendarEvent(resource)];
		}

		case actions.createCalendarEvent.type: {
			const resource = formatCalendarEventCreateFormOutput({
				form: payload,
				slotSelection: state.slotSelection,
			});
			return [{...state, processing: true}, effects.createCalendarEvent(resource)];
		}
		case actions.deleteCalendarEvent.type: {
			return [{...state}, effects.deleteCalendarEvent(payload)];
		}

		case actions.restoreCalendarEvent.type: {
			return [{...state}, effects.restoreCalendarEvent(payload)];
		}

		case actions.searchCalendarEventTypes.type: {
			return [state, effects.searchCalendarEventTypes(payload)];
		}

		case actions._updateCalendarEvent.type: {
			let newItemsSource = state.itemsSource;
			const itemMatches = (i, payload) => {
				return i.type === payload.type && i.id === payload.id;
			};

			if (state.selectedItem && state.selectedItem.teamId !== payload.teamId) {
				// item's team was changed, add or remove it from itemsSource depending on active team
				// item's team can only be changed if it's selected, so we can check the initial team from selectedItem
				const activeTeamId = state.calendarQuery.teamId;
				if (payload.teamId === activeTeamId) {
					newItemsSource = append(payload, state.itemsSource);
				} else {
					newItemsSource = reject(i => itemMatches(i, payload), state.itemsSource);
				}
			} else {
				// update the item in itemsSource
				newItemsSource = state.itemsSource.map(i => {
					if (itemMatches(i, payload)) return payload;
					return i;
				});
			}

			const [hourlessItems, hourItems] = normalizeCalendarResources(
				state.calendarQuery.weekSample,
				newItemsSource,
			);

			// if the updated item is currently selected, update the selected item
			const selectedItem =
				state.selectedItem && state.selectedItem.id === payload.id
					? pipe(normalizeItem, assoc('virtual', state.selectedItem.virtual))(payload)
					: state.selectedItem;

			// if the updated item is in buildingCalendarResources, update it there too
			const buildingCalendarResources = state.buildingCalendarResources.length
				? state.buildingCalendarResources.map(r => {
						if (r.id === payload.id) return payload;
						return r;
				  })
				: state.buildingCalendarResources;

			const newState = {
				...state,
				hourlessItems,
				hourItems,
				itemsSource: newItemsSource,
				selectedItem,
				buildingCalendarResources,
			};

			return [newState, null];
		}

		case actions._setCalendarEventTypes.type: {
			return [{...state, calendarEventTypes: payload}, null];
		}

		default:
			return pipeHandlers(reminderHandler)(state, fullState, {
				type,
				payload,
			});
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
